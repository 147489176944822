import { eventBus } from "global/event-bus";
import { AIComm } from "./AIComm";
import { Comm } from "./Comm";
import { ChatSettingsInferenceTemplate, Model, SendMessageData, TextReply } from "./Model";
import { UrlCache } from "./UrlCache";
import { ZService } from "./ZSettingsfn";
import { RealtimeClient } from "@openai/realtime-api-beta";

export class OpenAIComm extends AIComm {
  client: RealtimeClient;

  constructor(name: string, urlBase: string, model: string, key: string, proxyURL?: string) {
    super(name, urlBase, model, key, proxyURL);
    this.canHandleSpecialAttachments = true;
  }

  async getModelsFresh() {
    if (!this.apiKey) {
      return [];
    }

    const url = this.apiURL + '/models';
    const auth = await this.getAuthHeader();
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application json',
        [auth.key]: auth.value
      }
    });

    const data = await response.json();
    return data;
  }

  async getModelsClass(service: ZService = null): Promise<Model[]> {
    if (this.wasCalled) {
      return service ? service.models : [];
    }

    this.wasCalled = true;
    //ZService.debug(service, 'before:getModelsClass');
    // get fresh list using urlcache at 60 seconds
    // const freshModels = UrlCache.getSet('openai-models', () => this.getModelsFresh(), 60);
    const json = await Comm.getAllModelsJson();
    this.keyUrl = json.openai.keyUrl;
    const models = Model.loadModels(json.openrouter, this);
    const openAIModels = models.filter(m => m.id.startsWith("openai/"));
    openAIModels.map(m => {
      m.id = m.id.substring(7);
      m.serviceUid = service.uid;
    });

    const freshModels = await UrlCache.getSet('openai-models', async () => await this.getModelsFresh(), 60);
    if (freshModels && freshModels.data && freshModels.data.length > 0) {
      freshModels.data.map(item => {
        if (openAIModels.findIndex(x => x.id === item.id) < 0) {
          // new model
          const model = Model.construct3(item.id, item.id, 0);
          model.owner = 'openai';
          var mod = 'text';
          if (item.id.indexOf('tts') > -1) {
            mod = 'voice';
          } else if (item.id.indexOf('image') > -1) {
            mod = 'image';
          } else if (item.id.indexOf('whisper') > -1) {
            mod = 'audio';
          } else if (item.id.indexOf('embed') > -1) {
            mod = 'embedding';
          }
          model.architecture = { modality: mod, tokenizer: 'gpt4', instruct_type: 'instruct' };
          model.comm = this;
          model.serviceUid = service.uid;
          openAIModels.push(model);
        }
      });
    }

    // this.addInServiceModels(openAIModels, service);
    console.log('openai models:' + openAIModels.length);
    this.isConnected = true;

    // default
    // if all models are unselected, choose gpt4o as selected
    if (openAIModels.filter(m => m.isSelected).length === 0) {
      const gpt4o = openAIModels.find(m => m.id.indexOf('gpt4o') > -1);
      if (gpt4o) {
        gpt4o.isSelected = true;
      }
    }

    return openAIModels;
    // if (!this.apiKey) {
    //     return [];
    // }

    // const url = this.apiURL + '/models';
    // const auth = this.getAuthHeader();
    // const response = await fetch(url, {
    //     method: 'GET',
    //     headers: {
    //         'Content-Type': 'application json',
    //         [auth.key]: auth.value
    //     }
    // });

    // const data = await response.json();
    // return data;

  }

  async getAuthHeader(model: Model = null): Promise<{ key: string; value: string }> {
    const key = 'Authorization';
    const value = `Bearer ${this.apiKey}`;
    return { key, value };
  }

  getURLwithSuffix(url, model) {
    // if url has / on end, remove it
    if (url.endsWith('/')) {
      url = url.substring(0, url.length - 1);
    }

    if (model.id.indexOf('tts') > -1) {
      return url + '/audio/speech';
    }

    if (model.id.indexOf('whisper') > -1) {
      return url + '/audio/transcriptions';
    }

    return url + '/chat/completions';
  }

  fixModelId(id) {
    if (this.name === 'groq') {
      return id.replace('groq/', '');
    }

    return id;
  }

  async callDirect(instruction: string, infTemp: ChatSettingsInferenceTemplate | null, model: Model): Promise<string> {
    const data = {
      model: this.fixModelId(model.id),
      stream: false,
      messages: [
        { role: 'system', content: instruction }
      ],
    };

    const maxTokens = infTemp.inference?.max_tokens ?? 0;
    if (maxTokens > 0) {
      data['max_tokens'] = maxTokens;
    }

    const url = this.getURLwithSuffix(this.apiURL, model);
    const auth = await this.getAuthHeader(model);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        [auth.key]: auth.value
      },
      body: JSON.stringify(data)
    });

    const text = await response.text();
    return text;
  }

  async callTTS(text: string, key: string, voiceModel: Model, voice: string = 'alloy'): Promise<string | void> {
    const url = this.getURLwithSuffix(this.apiURL, voiceModel);
    const auth = await this.getAuthHeader(voiceModel);

    let body = JSON.stringify({
      "model": voiceModel.id,
      "input": text,
      "voice": voice
    });

    return await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        [auth.key]: auth.value
      },
      body: body
    })
      .then(response => response.blob())
      .then(blob => {
        let url = window.URL.createObjectURL(blob);
        return url;
      })
      .catch(error => console.log('Error:', error));
  }

  async callListen(blob: Blob, listenModel: Model, convId: string, mode: string) {
    const url = this.getURLwithSuffix(this.apiURL, listenModel);
    const auth = await this.getAuthHeader(listenModel);

    const file = new File([blob], 'recording.ogg', { type: 'audio/ogg; codecs=opus' });
    const data = new FormData();
    data.append('model', 'whisper-1');
    data.append('file', file);

    return await fetch(url, {
      method: 'POST',
      headers: {
        [auth.key]: auth.value
      },
      body: data as any
    })
      .then(response => response.json())
      .then(responseData => {
        eventBus.emit('gotSpeechText', { text: responseData.text, isError: false, convId: convId, mode: mode })
      })
      .catch(error => {
        console.error(error);
        eventBus.emit('gotSpeechText', { text: '', isError: true, error: error, convId: convId, mode: mode });
      });
  }

  // async sayThruOpenAIToUrl(text: string, key: string, voice: string = 'alloy') {
  //   let url = 'https://api.openai.com/v1/audio/speech';
  //   let headers = new Headers();
  //   headers.append('Authorization', 'Bearer ' + key);
  //   headers.append('Content-Type', 'application/json');

  //   let body = JSON.stringify({
  //     "model": "tts-1",
  //     "input": text,
  //     "voice": voice
  //   });

  //   var response = await fetch(url, {
  //     method: 'POST',
  //     headers: headers,
  //     body: body
  //   })
  //     .catch(error => console.log('Error:', error));

  //   var blob = await (response as any).blob();
  //   var urlAudio = window.URL.createObjectURL(blob);
  //   return urlAudio;
  // }

  parseTextReply(text: string, model: Model, ms = 0): TextReply {
    const data = JSON.parse(text);
    if (data.choices[0]?.delta?.content) {
      return { text: data.choices[0].delta.content, isDone: false, ms: ms };
    }
    if (data.choices[0]?.message?.content) {
      return { text: data.choices[0].message.content, isDone: false, ms: ms };
    }

    return null;
  }

  readFileAsBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const binaryString = reader.result as string;
        const base64String = btoa(binaryString);
        resolve(base64String);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsBinaryString(file);
    });
  }

  updateData(data, apiURL, proxyURL): string {
    return apiURL;
  }

  updateHeaders(data, headers, serviceHeaders: string[]) {
    return;
  }

  //imgs:06
  async call(callData: SendMessageData, stream: boolean = true): Promise<string> {
    const instruction = callData.preInstructions;
    const msgs = callData.msgs;
    const infTemp = callData.infTemp;
    let temperature = callData.infTemp.inference.temp;
    const update = callData.update;
    const model = callData.model;
    const chatId = callData.chatId;

    // if voice model, don't submit
    if (model.architecture?.modality?.indexOf('voice') > -1) {
      return "";
    }

    const messages = [];
    for (let m of msgs) {
      if (m.type === 'system') {
        continue;
      }
      var content: any = m.message;
      if ((m.images && m.images.length > 0) || (m.files && m.files.length > 0)) {
        content = [
          {
            type: "text",
            text: m.message
          }
        ];

        if (m.images && m.images.length > 0) {
          for (let img of m.images) {
            content.push({
              type: "image_url",
              image_url: {
                url: img
              }
            });
          }
        }

        if (m.files && m.files.length > 0) {
          for (let file of m.files) {
            const base64String = file.contentBase64;
            const json = {
              file: file.name,
              encoded: 'base64',
              micro_instruction: 'Using the attached file, respond directly USING the content as if it were ALREADY provided in plain text, without mentioning the encoding or decoding process. Answer questions using the content, not replying with the content, unless asked.', content: base64String
            };
            content.push({
              type: "text",
              text: JSON.stringify(json)
            });
          }
        }

        if (!this.canHandleSpecialAttachments) {
          content = JSON.stringify(content);
        }
      }
      messages.push({
        role: m.type,
        content: content
      });
    }

    //SPECIAL CASE
    const isO1 = callData.model.id.startsWith("o1");
    if (isO1) {
      console.log('o1 found - fixing params');
      stream = false;
      infTemp.inference.temp = 1;
      temperature = 1;
      infTemp.inference.top_p = 1;
      infTemp.inference.n = 1;
      infTemp.inference.frequency_penalty = 0;
    }

    const data = {
      model: this.fixModelId(model.id),
      messages: messages,
      stream: stream,
      temperature: temperature
    };

    const maxTokens = infTemp.inference?.max_tokens ?? 0;
    if (maxTokens > 0) {
      data['max_tokens'] = maxTokens;
    }

    if (callData.sections && callData.sections.length > 0) {
      const section = callData.sections[0];
      if (section.key === 'json_schema') {
        data["response_format"] = {
          "type": "json_schema",
          "json_schema": JSON.parse(section.value.trim())
        };
      }
    }

    if (instruction) {
      if (isO1) {
        // if 1st msg only...
        const countUserMessages = msgs.filter(m => m.type === 'user').length;
        if (countUserMessages === 1) {
          // insert the instruction into the first message
          data.messages[0].content = instruction + '\n\n' + data.messages[0].content;
        }
      }
      else {
        data.messages.unshift({ role: "system", content: instruction });
      }
    }

    // console.log('SUB TO MODEL:' + model.id + ":");
    const apiURL = this.updateData(data, this.apiURL, this.proxyURL);
    // console.log(data);

    const url = this.getURLwithSuffix(apiURL, model); //this.fixModelId(model.id));
    const auth = await this.getAuthHeader(model);
    if (auth.value === 'unauthorized') {
      throw new Error('unauthorized');
    }
    const startTime = new Date().getTime();

    const headers = {
      'Content-Type': 'application/json',
      [auth.key]: auth.value
    };

    this.updateHeaders(data, headers, [this.header1, this.header2, this.header3]);

    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(data)
    });

    if (stream) {
      await this.parseAndSend(response, update, model, startTime);
      return "";
    } else {
      const result = await response.json();
      if (result.error) {
        update({ text: result.error, model: model, isError: true, isDone: true, ms: new Date().getTime() - startTime },
          model, true, true);
        return "";
      }
      else {
        const content = result.choices[0]?.message?.content || '';
        update({ text: content, model: model, isError: false, isDone: true, ms: new Date().getTime() - startTime },
          model, false, true);
        return content;
      }
    }
  }

  //--------------- real-time streaming ----------------

  initRealtime() {
    this.client = new RealtimeClient({ apiKey: this.apiKey, dangerouslyAllowAPIKeyInBrowser: true, debug: false });

    // Update session parameters
    // Ensure that the sample rate is set to 24000 Hz
    this.client.updateSession({
      instructions: 'You are a great, upbeat friend.',
      voice: 'alloy',
      turn_detection: { type: 'server_vad' }, // Use 'none' if you want to control manually
      input_audio_transcription: { model: 'whisper-1' },
      input_audio_format: 'pcm16'
    });

    // Set up event handling
    this.setupEventListeners();
  }


  private setupEventListeners() {
    this.client.on('server.response.audio_transcript.delta', ({ item, delta }) => {
      debugger;
    })

    // Handle conversation updates
    this.client.on('conversation.updated', ({ item, delta }) => {
      const items = this.client.conversation.getItems();
      console.log(delta);
      // Emit a custom event or handle the update as needed
      //eventBus.emit('conversation.updated', { item, delta, items });

      // Example: Handle text responses
      if (item.type === 'message' && item.role === 'assistant') {
        const text = item.content.find((c: any) => c.type === 'audio')?.transcript || '';
        eventBus.emit('text-response', text);
      }

      // Example: Handle audio responses
      if (delta?.audio) {
        eventBus.emit('audio-response', delta.audio.buffer);
      }
    });

    // Handle errors
    this.client.on('error', (error: any) => {
      console.error("RealtimeClient Error:", error);
      eventBus.emit('error', error);
    });

    // Handle connection closures
    this.client.on('close', () => {
      console.log("RealtimeClient connection closed.");
      eventBus.emit('close');
    });
  }

  /**
   * Connects to the OpenAI Realtime API.
   */
  async connect() {
    try {
      if (!this.client) {
        this.initRealtime();
      }
      if (!this.isConnected) {
        await this.client.connect();
        this.isConnected = true;
      }
      console.log("Connected to OpenAI Realtime API.");
    } catch (error) {
      console.error("Failed to connect to OpenAI Realtime API:", error);
      eventBus.emit('error', error);
    }
  }

  /**
   * Disconnects from the OpenAI Realtime API.
   */
  disconnect() {
    if (this.client) {
      this.client.disconnect();
      this.isConnected = false;
      console.log("Disconnected from OpenAI Realtime API.");
    }
  }

  /**
   * Sends a user text message to OpenAI.
   * @param text - The text content to send.
   */
  sendUserMessage(text: string) {
    if (!this.isConnected) {
      console.warn("Client is not connected. Attempting to connect...");
      this.connect().then(() => {
        this.client.sendUserMessageContent([{ type: 'input_text', text }]);
      }).catch(error => {
        console.error("Connection error:", error);
      });
      return;
    }

    this.client.sendUserMessageContent([{ type: 'input_text', text }]);
  }


  /**
   * Converts an ArrayBuffer to an Int16Array.
   * @param buffer - The ArrayBuffer to convert.
   * @returns The resulting Int16Array.
   */
  private convertArrayBufferToInt16Array(buffer: ArrayBuffer): Int16Array {
    const view = new DataView(buffer);
    const int16Array = new Int16Array(buffer.byteLength / 2);
    for (let i = 0; i < int16Array.length; i++) {
      int16Array[i] = view.getInt16(i * 2, true); // little-endian
    }
    return int16Array;
  }

  /**
   * Example method to add tools if needed.
   * Adjust according to your specific tools and use-cases.
   */
  addTool(tool: any, callback: (params: any) => any) {
    this.client.addTool(tool, callback);
  }

  /**
   * Example method to manually send events if needed.
   */
  sendCustomEvent(eventType: string, payload: any) {
    this.client.realtime.send(eventType, payload);
  }

  /**
   * Updates the session with new parameters.
   * @param params - The session parameters to update.
   */
  updateSession(params: any) {
    this.client.updateSession(params);
  }

  /**
   * Clean up resources when the instance is destroyed.
   */
  destroy() {
    this.disconnect();
    //this.client.removeAllListeners();
  }


}


